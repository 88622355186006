import React from "react";
import Seo from "../components/Seo/Seo"
import PageLayout from "../layouts/PageLayout";
import { graphql } from "gatsby";
import { SliceZone } from "../components/SlizeZone/SliceZone";

const homepage = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicHomepage
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords

    return (

        <PageLayout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeywords}
            />
            <SliceZone slices={page.body}/>
        </PageLayout>
    )
}


export default homepage

export const query = graphql`
query HomePageQuery($id: String) {
  prismicHomepage(id: {eq: $id}) {
    id
    url
    uid
    lang
    data {
      flag_page
      index_page
      meta_description
      meta_keywords
      meta_title
      body {
        ... on PrismicHomepageDataBodyExchangesSlice {
          id
          slice_label
          slice_type
          items {
            name
            icon {
              url
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicHomepageDataBodyHeroSlice {
          id
          slice_type
          slice_label
          primary {
            title
            btn_primary
            btn_secondary
            cqs_subtitle
            cqs_title
            description
            icon {
              url
              gatsbyImageData
              alt
            }
            img {
              alt
              gatsbyImageData
              url
            }
            btn_primary_link
            btn_secondary_link
          }
        }
        ... on PrismicHomepageDataBodyTradingSoftwareContent {
          id
          slice_type
          slice_label
          primary {
            title
            subtitle
            heading
            content
          }
        }
        ... on PrismicHomepageDataBodyVideoSection {
          id
          slice_type
          slice_label
          primary {
            videotitle
            videoid
            title
            content
          }
        }
      }
    }
  }
}
`;  